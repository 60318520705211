/* eslint-disable i18next/no-literal-string */
import React from "react";

import { DropdownItem } from "ds/Dropdown";
import { Icon } from "ds/icons";
import { useLocaleContext } from "utils/context/locale/useLocaleContext";
import { isDevOrQA } from "utils/featureFlags";

/**
 * This component allows internal users to toggle the in-context translation mode.
 * It should NEVER be enabled in production. It is only intended for use in development and QA environments.
 */

export const InContextTranslationToggle = () => {
  const { locale, onChangeLocale } = useLocaleContext();
  if (!isDevOrQA) return null;

  /**
   * We use "tlh" (Klingon) as the locale code for Crowdin's In-context UI.
   * The in-context feature requires the use of a supported locale code for storing metadata.
   * We chose Klingon to prevent any potential conflicts with real locales.
   *
   * see: https://crowdin.com/project/turn-translations/tools/in-context
   */
  const enabled = locale === "tlh";
  const toggleInContextTranslation = () => {
    onChangeLocale(enabled ? "en" : "tlh");
    window.location.reload();
  };
  return (
    <DropdownItem
      label={`${enabled ? "Disable" : "Enable"} translation mode`}
      onClick={toggleInContextTranslation}
      icon={<Icon name="Translate_01Icon" />}
    />
  );
};

import gql from "graphql-tag";

// import { NUMBER_TAG_DETAILS_FRAGMENT } from "./numberTags";

export const MESSAGE_TEMPLATE_DETAILS_FRAGMENT = gql`
  fragment MessageTemplateDetails on MessageTemplateSchema {
    uuid
    name
    language
    content
    status
    category
    graphId
    insertedAt
    updatedAt
    rejectedReason
    components {
      type
      ... on MessageTemplateSchemaComponentBody {
        text
      }
      ... on MessageTemplateSchemaComponentHeader {
        headerText: text
        format
      }
      ... on MessageTemplateSchemaComponentFooter {
        text
      }
      ... on MessageTemplateSchemaComponentButtons {
        buttons {
          type
          text
          ... on MessageTemplateSchemaButtonPhoneNumber {
            phoneNumber
          }
          ... on MessageTemplateSchemaButtonUrl {
            url
          }
        }
      }
    }
  }
`;

// export const NUMBER_DETAILS_FRAGMENT = gql`
//   fragment NumberDetailsFragment on Number {
//     numberType
//     backendType
//     address
//     about
//     vertical
//     email
//     websites
//     description
//     aboutUpdatedAt
//     backendType
//     fromAddr
//     numberCode
//     numberTags {
//       ...NumberTagDetails
//     }
//     numberRoute {
//       uuid
//       fromAddr
//     }
//     webhooks {
//       enabled
//       hmacDigest
//       hmacSecret
//       primary
//       subscriptions
//       url
//       uuid
//     }
//     collections(orderBy: "name") {
//       uuid
//       name
//       messagesOrChatsTotalEstimate
//       type
//       query {
//         tags
//       }
//     }
//     contactSchema {
//       latest
//       uuid
//       contactFields {
//         fieldName
//         fieldType
//         fieldDisplay
//         fieldSpec {
//           defaultValue
//           validatorType
//           validatorParams
//         }
//       }
//     }
//     uuid
//     integrations {
//       ...IntegrationDetails
//     }
//     messageTemplates {
//       ...MessageTemplateDetails
//     }
//   }

//   ${NUMBER_TAG_DETAILS_FRAGMENT.query}
//   ${INTEGRATION_DETAILS_FRAGMENT}
//   ${MESSAGE_TEMPLATE_DETAILS_FRAGMENT}
// `;

export const AUTOMATOR_RULE = gql`
  fragment AutomatorRuleDetails on AutomatorRule {
    actions {
      actionParams
      actionType
    }
    operator
    triggers {
      triggerParams
      triggerType
    }
  }
`;

export const AUTOMATOR_DETAILS = gql`
  fragment AutomatorDetailsFragment on Automator {
    config {
      data {
        ...AutomatorRuleDetails
      }
      version
    }
    enabled
    insertedAt
    name
    updatedAt
    uuid
  }
  ${AUTOMATOR_RULE}
`;

import { Box, Button, DropButton } from "grommet";
import React from "react";
import styled from "styled-components";

import { ColorGrayDark, ColorPurpleDark, ColorPurpleLightest } from "ds/Core";

import { Selected } from "../icons/Icons";

/* STYLES
------------------------------------------ */

const StyledDropIconButton = styled(DropButton)`
  opacity: 0.8;
  padding: 8px;
  background: #f6f8f8;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.15s ease-in;

  &:hover {
    opacity: 1;
    background: #ebeded;
  }
  ${(props) =>
    props.plain &&
    `background:transparent;
    &:hover{background:transparent}`}
  ${(props) => props.disabled && "opacity:0.5"}
`;

const StyledDropButtonTextIcon = styled(DropButton)`
  transition: all 0.15s ease-in;
  color: ${({ color }) => color || ColorPurpleDark};
  border-color: transparent;
  padding: ${({ padding }) => padding || "6px 16px"};
  border-radius: 32px;

  & svg {
    transition: all 0.15s ease-in;
    fill: ${({ color }) => color || ColorPurpleDark};
    width: 16px;
    height: 16px;

    & path {
      fill: ${({ color }) => color || ColorPurpleDark};
    }
  }
  &:hover {
    color: ${({ color }) => color || ColorGrayDark};

    & svg {
      fill: ${({ color }) => color || ColorGrayDark};

      & path {
        fill: ${({ color }) => color || ColorGrayDark};
      }
    }
  }
`;

export const DropMenuBtn = styled(Button)`
  padding: 12px 24px;
  font-weight: 400;
  font-size: 14px;
  & > div {
    justify-content: space-between;
    width: 100%;
  }

  &:hover {
    background-color: ${ColorPurpleLightest};
  }
`;

export const DropMenuItem = styled(Box)`
  padding: 8px 24px;
`;

/* COMPONENTS
------------------------------------------ */
export const DropMenuContainer = ({ children, ...props }) => {
  return (
    <Box flex="grow" gap="xxsmall" width="280px" pad="8px 0" {...props}>
      {children}
    </Box>
  );
};
// Dropdown Styles
export const DropButtonIcon = (props) => {
  return <StyledDropIconButton {...props} />;
};
export const DropButtonTextIcon = (props) => {
  return <StyledDropButtonTextIcon {...props} />;
};

export const SelectedTag = ({ width = 16, height = 16, fill, ...props }) => {
  return (
    <div style={{ margin: "0.6em 0 0 0.6em" }} {...props}>
      <Selected width={width} height={height} fill={fill} />
    </div>
  );
};

import styled from "styled-components";

import { BackgroundColorGray } from "ds/Alias";
import { Button } from "ds/Button";
import { ColorGrayLight, Space50, Space100, Space150 } from "ds/Core";

export const StyledTooltipContainer = styled.div`
  position: relative;
  width: 232px;
  display: flex;
  flex-direction: column;
  padding: ${Space100};
  gap: ${Space50};
  border: 1px solid ${ColorGrayLight};
  border-radius: 4px;
  background-color: ${BackgroundColorGray};
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: ${Space150};
  top: ${Space150};
`;

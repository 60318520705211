import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import {
  EXPRESSION_FRAGMENT,
  NOTEBOOK_DETAILS_FRAGMENT,
  SAMPLE_NOTEBOOK_DETAILS_FRAGMENT,
  STACK_CONTAINER_DETAILS_FRAGMENT,
  STACK_CONTAINER_PREVIEW_FRAGMENT,
  STACK_CONTAINER_PREVIEW_MIN_FRAGMENT,
  TRIGGER_DETAILS_FRAGMENT,
  TRIGGER_META_FRAGMENT,
} from "./fragments";

export const GENERATE_CODE_FOR_CARDS = gql`
  query generateCodeForCards($cards: [StackCardGeneratorIo!]!) {
    generateCodeForCards(cards: $cards) {
      value
      type
    }
  }
`;

export const GENERATE_CODE_FOR_TRIGGERS = gql`
  query generateCodeForTriggers($numberUuid: ID!, $triggers: [TriggerIo!]!) {
    generateCodeForTriggers(numberUuid: $numberUuid, triggers: $triggers) {
      value
      type
      issues {
        type
        line
        column
        snippet
        description
      }
    }
  }
`;

export const TRIGGER_META = gql`
  query generateTriggerMeta($numberUuid: ID!, $triggers: [TriggerIo!]!) {
    generateCodeForTriggers(numberUuid: $numberUuid, triggers: $triggers) {
      ...TriggerMetaDetails
    }
  }
  ${TRIGGER_META_FRAGMENT}
`;

export const PARSE_NOTEBOOK = gql`
  query parseNotebook($notebook: NotebookIo!, $numberUuid: ID) {
    parseNotebook(notebook: $notebook, numberUuid: $numberUuid) {
      ...NotebookDetails
    }
  }

  ${NOTEBOOK_DETAILS_FRAGMENT.query}
`;

export const LIST_SAMPLE_NOTEBOOKS = gql`
  query listSampleNotebooks {
    listSampleNotebooks {
      ...SampleNotebookDetails
    }
  }

  ${SAMPLE_NOTEBOOK_DETAILS_FRAGMENT}
`;

export const GET_STACK_CONTAINER = {
  query: gql`
    query getStackContainer($numberUuid: ID!, $stackContainerUuid: ID!) {
      getStackContainer(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
        ...StackContainerDetails
      }
    }

    ${STACK_CONTAINER_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    getStackContainer: STACK_CONTAINER_DETAILS_FRAGMENT.parse,
  }),
};

export const GET_NOTEBOOK_REVISIONS = {
  query: gql`
    query getNotebookRevisions(
      $numberUuid: ID!
      $stackContainerUuid: ID!
      $limit: Int
      $offset: Int
      $onlyNamedRevisions: Boolean
    ) {
      getStackContainer(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
        uuid
        notebookRevisions(limit: $limit, offset: $offset, onlyNamedRevisions: $onlyNamedRevisions) {
          hasNextPage
          revisions {
            accountUuid
            insertedAt
            uuid
            versionName
          }
        }
      }
    }
  `,
  parse: boundary({
    getStackContainer: {
      uuid: required(),
      notebookRevisions: {
        revisions: required(),
      },
    },
  }),
};

export const GET_NOTEBOOK_REVISION = gql`
  query getNotebookRevision($numberUuid: ID!, $notebookRevisionUuid: ID!) {
    getNotebookRevision(numberUuid: $numberUuid, notebookRevisionUuid: $notebookRevisionUuid) {
      accountUuid
      insertedAt
      uuid
      versionName
      notebook {
        ...NotebookDetails
      }
    }
  }

  ${NOTEBOOK_DETAILS_FRAGMENT.query}
`;

export const EXPORT_STACK = gql`
  mutation createStackDataExport($numberUuid: ID!, $objectUuid: ID!, $type: String!) {
    createStackDataExport(numberUuid: $numberUuid, objectUuid: $objectUuid, type: $type) {
      status
      uuid
    }
  }
`;

export const LIST_STACK_CONTAINERS_PREVIEWS = {
  query: gql`
    query listStackContainersPreviews($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String, $search: String) {
      listStackContainersPreviews(
        numberUuid: $numberUuid
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        search: $search
      ) {
        count
        stackContainerPreviews {
          ...StackContainerPreview
        }
      }
    }

    ${STACK_CONTAINER_PREVIEW_FRAGMENT.query}
  `,
  parse: boundary({
    listStackContainersPreviews: {
      stackContainerPreviews: {
        ...STACK_CONTAINER_PREVIEW_FRAGMENT.parse,
      },
    },
  }),
};

export const LIST_STACK_CONTAINERS_PREVIEWS_MIN = gql`
  query listStackContainersPreviewsMin($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String, $search: String) {
    listStackContainersPreviews(
      numberUuid: $numberUuid
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      search: $search
    ) {
      count
      stackContainerPreviews {
        ...StackContainerPreviewMin
      }
    }
  }

  ${STACK_CONTAINER_PREVIEW_MIN_FRAGMENT}
`;

export const LIST_STACK_CONTAINERS = {
  query: gql`
    query listStackContainers($numberUuid: ID!) {
      listStackContainers(numberUuid: $numberUuid) {
        uuid
        name
        updatedAt
        isNotebookValid
        isCanvas
        triggers {
          ...TriggerDetails
        }
      }
    }
    ${TRIGGER_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    listStackContainers: {
      ...STACK_CONTAINER_PREVIEW_FRAGMENT.parse,
    },
  }),
};

export const EXPRESSION_TO_STRING = gql`
  query generateCodeForExpression($items: [ExpressionBasicIo!]!) {
    generateCodeForExpression(items: $items)
  }
  ${EXPRESSION_FRAGMENT}
`;

export const EXPRESSION_TO_CODE = gql`
  query parseExpression($expression: String!) {
    parseExpression(expression: $expression) {
      ...ExpressionDetails
    }
  }
  ${EXPRESSION_FRAGMENT}
`;

export const LIST_JOURNEY_GLOBAL_DATA = gql`
  query listJourneyGlobalData($numberUuid: ID!) {
    listJourneyGlobalData(numberUuid: $numberUuid) {
      uuid
      name
      data {
        id
        type
        ... on JourneyDataDictionary {
          dictionary {
            key
            value
          }
        }
        ... on JourneyDataTable {
          table {
            columns {
              id
              index
              key
            }
            rows {
              id
              index
              items {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_JOURNEY_LOCAL_DATA = gql`
  query listJourneyLocalData($numberUuid: ID!, $stackContainerUuid: ID!) {
    listJourneyLocalData(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      name
      data {
        id
        type
        ... on JourneyDataDictionary {
          dictionary {
            key
            value
          }
        }
        ... on JourneyDataTable {
          table {
            columns {
              id
              index
              key
            }
            rows {
              id
              index
              items {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const JOURNEY_TRANSLATIONS_FRAGMENT = gql`
  fragment JourneyTranslationsFragment on JourneyTable {
    ... on JourneyTable {
      columns {
        id
        index
        key
      }
      rows {
        id
        index
        items {
          key
          value
        }
      }
    }
  }
`;

export const GET_JOURNEY_TRANSLATIONS = gql`
  ${JOURNEY_TRANSLATIONS_FRAGMENT}

  query getJourneyTranslations($numberUuid: ID!, $journeyUuid: ID!) {
    getJourneyTranslations(numberUuid: $numberUuid, journeyUuid: $journeyUuid) {
      ...JourneyTranslationsFragment
    }
  }
`;

export const UPDATE_JOURNEY_TRANSLATIONS = gql`
  ${JOURNEY_TRANSLATIONS_FRAGMENT}

  mutation updateJourneyTranslations($translations: JourneyTableIo!, $numberUuid: ID!, $journeyUuid: ID!) {
    updateJourneyTranslations(translations: $translations, numberUuid: $numberUuid, journeyUuid: $journeyUuid) {
      ...JourneyTranslationsFragment
    }
  }
`;

export const GET_JOURNEY_INSIGHTS = gql`
  query getJourneyInsights($numberUuid: ID!, $journeyUuid: ID!) {
    getJourneyInsights(numberUuid: $numberUuid, journeyUuid: $journeyUuid) {
      insights {
        identity
        count
      }
      total
    }
  }
`;

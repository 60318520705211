import React from "react";

import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { ColorNeutralWhite, ShadowLow } from "ds/Core";
import { FeatureCallout } from "ds/FeatureCallout/src/FeatureCallout";
import { TInitialNumber } from "graphqlQueries";
import { localeOptions } from "utils/context/locale/constants";
import { useLocaleContext } from "utils/context/locale/useLocaleContext";

type Props = {
  number: TInitialNumber;
};

export const UITranslationCallout = ({ number }: Props) => {
  // Only show this callout for numbers from Brazil since the first UI translations release
  // is only targeting brazilian users. This is a temporary solution until we have a better
  // way to target users based on their country.
  const isBrazillianNumber = number.fromAddr?.slice(0, 3) === "+55";
  const { locale, onChangeLocale } = useLocaleContext();

  const locales = localeOptions.map(({ value }) => value);
  const targetLocale = navigator.languages.find((code) => locales.includes(code.slice(0, 2)))?.slice(0, 2);

  const _active =
    (isBrazillianNumber || targetLocale === "pt") &&
    locale !== "pt" &&
    window.localStorage.getItem("ui-translations-callout-viewed") !== "true";

  const [active, setActive] = React.useState(_active);

  const handleDismiss = () => {
    setActive(false);
    window.localStorage.setItem("ui-translations-callout-viewed", "true");
  };
  const handleSwitchLanguage = () => {
    handleDismiss();
    onChangeLocale("pt");
  };

  return (
    <FeatureCallout
      active={active}
      title="🇧🇷 Olá, falamos Português"
      description="Good news! The Turn.io interface is now available in Portuguese."
      videoLink="https://www.youtube.com/embed/_I3GsXNjeYI?si=ZyuVU5n-SIwjDUg1"
      onDismiss={handleDismiss}
      actions={
        <Box flexDirection="column" gap="100">
          <Button
            onClick={handleSwitchLanguage}
            variation="primary"
            size="micro"
            text="Mudar para Português"
            style={{ boxShadow: ShadowLow }}
          />
          <Button
            onClick={handleDismiss}
            variation="secondary"
            size="micro"
            text="Keep English"
            style={{ boxShadow: ShadowLow, backgroundColor: ColorNeutralWhite }}
          />
        </Box>
      }
    />
  );
};

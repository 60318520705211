import React, { FC, createContext, useCallback, useState } from "react";

import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";

import { DEFAULT_LOCALE, localeOptions } from "./constants";
import { getLocaleCookie } from "./utils/cookies";
import { applyLocale, initLocale } from "./utils/locale";

export const LocaleContext = createContext<{
  localeOptions: {
    value: string;
    label: string;
  }[];
  locale: string;
  onChangeLocale: (value: string) => void;
  onLocationChanged: () => void;
}>({
  localeOptions,
  locale: DEFAULT_LOCALE,
  onChangeLocale: () => null,
  onLocationChanged: () => null,
});

export const LocaleProvider: FC = ({ children }) => {
  const [locale, setLocale] = useState(() => initLocale());

  const onChangeLocale = useCallback((locale) => {
    setLocale(locale);
    applyLocale(locale, true);
  }, []);

  const onLocationChanged = useCallback(() => {
    if (isEnabledFeature(FeatureFlag.LOCALES) && !getLocaleCookie()) {
      const detectedLocale = initLocale();

      if (detectedLocale !== locale) {
        setLocale(detectedLocale);
      }
    }
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        localeOptions,
        locale,
        onChangeLocale,
        onLocationChanged,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

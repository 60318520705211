import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Joyride, { TooltipRenderProps } from "react-joyride";

import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { ColorNeutralWhite, ShadowLow } from "ds/Core";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";

import { StyledCloseButton, StyledTooltipContainer } from "./styles";

export type Props = {
  title?: string;
  description: ReactNode;
  active: boolean;
  onDismiss?: () => void;
  videoLink?: string;
  learnMoreUrl?: string;
  actions?: ReactNode;
};

export const FeatureCallout = ({ title, videoLink, onDismiss, description, active, learnMoreUrl, actions }: Props) => {
  return (
    <Joyride
      run={active}
      callback={(state) => {
        if (state.status === "finished") {
          onDismiss?.();
        }
      }}
      styles={{
        options: {
          spotlightShadow: "none",
        },
      }}
      steps={[
        {
          target: "#feature-callout",
          title,
          content: description,
          disableBeacon: true,
          disableOverlay: true,
          data: { videoLink, learnMoreUrl, actions },
        },
      ]}
      tooltipComponent={CustomTooltip}
      floaterProps={{ disableAnimation: true, hideArrow: true }}
    />
  );
};

const CustomTooltip = ({ closeProps, step, tooltipProps }: TooltipRenderProps) => {
  const { t } = useTranslation();
  return (
    <StyledTooltipContainer {...tooltipProps}>
      <StyledCloseButton
        variation="secondary"
        size="micro"
        icon={<Icon size="small" name="XCloseIcon" />}
        {...closeProps}
      />
      {step.data.videoLink ? (
        <iframe
          referrerPolicy="strict-origin-when-cross-origin"
          src={step.data.videoLink}
          allowFullScreen
          style={{ border: 0, borderRadius: "4px" }}
        />
      ) : (
        <Box mt="200" />
      )}
      <Box padding="100">
        {step.title && (
          <Text lineHeight="loose" preset="paragraph2-200">
            {step.title}
          </Text>
        )}
        <Text preset="small100" color="textColorSubtle" mt="50" mb="100">
          {step.content}
        </Text>
      </Box>
      {step.data.actions}
      {step.data.learnMoreUrl && (
        <Button
          variation="secondary"
          mt="100"
          size="micro"
          style={{ boxShadow: ShadowLow, backgroundColor: ColorNeutralWhite }}
          url={step.data.learnMoreUrl}
          text={t("btn.learn-more")}
        />
      )}
    </StyledTooltipContainer>
  );
};

export const FeatureCalloutTarget = () => (
  <div style={{ position: "absolute", bottom: -10, left: 10 }} id="feature-callout" />
);

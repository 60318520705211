import { DEFAULT_LOCALE } from "../constants";

export const setLocaleCookie = (locale: string) => (document.cookie = `locale=${locale}; path=/; max-age=34560000;`);

export const getLocaleCookie = () => {
  return (
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("locale="))
      ?.split("=")[1] || DEFAULT_LOCALE
  );
};
